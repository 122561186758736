<template>    
    <div id="app">
        <Sidebar></Sidebar>

        <div id="main">
            <header class="mb-3">
                <a href="#" class="burger-btn d-block d-xl-none">
                    <i class="bi bi-justify fs-3"></i>
                </a>
            </header>

            <div class="page-heading">
                <div class="page-title">
                    <div class="row">
                        <div class="col-12 col-md-6 order-md-1 order-last">
                            <h3 class="mb-4">Add Product</h3>
                            <!-- <p class="text-subtitle text-muted">List of all products</p> -->
                        </div>
                        <div class="col-12 col-md-6 order-md-2 order-first">
                            <nav aria-label="breadcrumb" class="breadcrumb-header float-start float-lg-end">
                            </nav>
                        </div>
                    </div>
                </div>
                <section class="section">
                    <div class="card">
                        <form class="container mt-4" @submit.prevent="submitData" id="form">
                            <div class="form-group mb-3">
                                <label for="name">Product Name</label>
                                <input type="text" id="name" class="form-control input-field" name="name">
                            </div>

                            <div class="form-group mb-3">
                                <label for="product_information">Product Information</label>
                                <textarea type="text" id="product_information" class="form-control input-field" name="product_information"></textarea>
                            </div>

                            <div class="form-group mb-3">
                                <label for="weight">Weight</label>
                                <input type="number" id="weight" class="form-control input-field" name="weight" placeholder="Weight in gram">
                            </div>

                            <div class="form-group mb-1">
                                <label for="category">Category</label>
                                <input type="text" id="category" class="form-control input-field" name="category">
                            </div>

                            <div class="form-group mb-4">
                                <label for="image_url" class="btn btn-sm alert-danger w-100 mb-2 main-image-label" style="color: white">Upload Gambar Produk</label>
                                <input type="file" name="image_url" id="image_url" hidden @change="showFile">
                            </div>

                            <div class="form-group mb-3" id="variant-form">
                                <label for="">Variant</label>
                                <div class="form-floating">
                                    <input type="text" name="variant_name" class="form-control input-field mb-4" placeholder="Nama Varian">
                                    <label>Variant Name</label>
                                </div>

                                <template v-for="i in variant">
                                    <div class="form-floating">
                                        <input type="text" class="form-control input-field mb-1" :name="`variant_name_${i}`" placeholder="Opsi">
                                        <label>Option</label>
                                    </div>

                                    <div class="form-floating">
                                        <input type="number" class="form-control input-field" :name="`variant_price_${i}`" placeholder="Harga">
                                        <label>Price</label>
                                    </div>

                                    <label :for="`variant_image_url_${i}`" class="btn btn-sm alert-danger w-100 mb-2 mt-1" :id="`variant_label_${i}`" style="color: white">Upload Image</label>
                                    <input type="file" :name="`variant_image_url_${i}`" :id="`variant_image_url_${i}`" hidden @change="showVariantFile(i)">
                                </template>

                                <button type="button" class="btn btn-sm w-100 btn-primary mt-2" @click="variant++">Add More Variant Option</button>
                            </div>

                            <div class="form-group mb-3" id="combination-form">
                                <label for="">Combination</label>
                                <template v-for="(combination, index) in combinationParents">
                                    <div class="form-floating">
                                        <input type="text" :name="`combination_${index + 1}`" class="form-control input-field mb-4" placeholder="Nama Kombinasi">
                                        <label>Combination Name</label>
                                    </div>

                                    <template v-for="(combination2, index2) in combination">
                                        <div class="form-floating">
                                            <input type="text" class="form-control input-field mb-1" placeholder="Opsi" :name="`combination_${index + 1}_name_${index2 + 1}`">
                                            <label>Option</label>
                                        </div>

                                        <div class="form-floating">
                                            <input type="number" class="form-control input-field mb-3" placeholder="Harga" :name="`combination_${index + 1}_price_${index2 + 1}`">
                                            <label>Price</label>
                                        </div>
                                    </template>

                                    <button type="button" class="btn btn-sm btn-primary w-100 mt-2 mb-3 d-block" @click="combinationParents[index].push(0)">Add More Combination Field</button>
                                </template>

                                <button type="button" class="btn btn-primary w-100 mt-2" @click="combinationParents.push([0])">Add More Combination</button>
                            </div>

                            <button type="submit" class="btn btn-success w-100 mt-4 mb-3" id="submit-button">Submit</button>
                        </form>
                    </div>
                </section>
            </div>

            <footer>
                <div class="footer clearfix mb-0 text-muted">
                    <div class="float-start">
                        <p>2021 &copy; Rinyuakng</p>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>

import Sidebar from '@/components/Sidebar.vue'
import axios from 'axios'

export default {
    components: {
        Sidebar
    },
    data() {
        return {
            token: localStorage.getItem('token'),
            variant: 1,
            combinationParents: [[0]]
        }
    },
    methods: {
        submitData() {
            const form = document.getElementById('form');
            const formData = new FormData(form);

            const button = document.querySelector('#submit-button');

            button.disabled = true;
            button.innerHTML = ` <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`;

            axios.post(`admin/product?token=${this.token}`, formData)
                .then(res => {
                    this.$router.push('/products');
                })
                .catch(err => {
                    button.disabled = false;
                    button.innerHTML = 'Submit';

                    alert(err.response.data.message);
                }); 
        },
        showFile() {
            const file = document.getElementById('image_url').files[0];
			const label = document.querySelector('#form .main-image-label');

			label.innerHTML = file.name;
        },
        showVariantFile(i) {
            const file = document.getElementById(`variant_image_url_${i}`).files[0];
            const label = document.querySelector(`#variant_label_${i}`);

            label.innerHTML = file.name;
        }
    }
}
</script>